<template lang="pug">
  div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingPropertyTypeList || isLoadingPropertyTypeListArchived || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des types de biens...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :allowGrouping="allowGrouping")
      popup-create-property-type(:archive="archive" ref="popupCreatePropertyType" @closePopupPropertyType="closePopupPropertyType")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import PopupCreatePropertyType from "@/components/billing/property-type/PopupCreatePropertyType";

export default {
  components: {
    PopupCreatePropertyType,
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "propertyType",
      uid: "propertyTypes",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un type de bien",
      allowGrouping: false,
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: false,
      },
      {
        type: "string",
        field: "label",
        headerText: "Nom",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "propertyTypeList",
      "propertyTypeListArchived",
      "isLoadingPropertyTypeList",
      "isLoadingPropertyTypeListArchived",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters[this.name + "ListArchived"];
      } else {
        return this.$store.getters[this.name + "List"];
      }
    },
  },
  created() {
    this.getPropertyTypes({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
      }
      this.indexSearch++;
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions(["getPropertyTypes", "getPropertyTypesArchived"]),
    setColumnsByObject,
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.popupCreatePropertyType("");
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getPropertyTypesArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getPropertyTypes({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.popupCreatePropertyType(event.rowData.id);
    },
    popupCreatePropertyType(id) {
      this.$bvModal.show("modal-create-property-type");
      this.$refs["popupCreatePropertyType"].popupCreatePropertyTypeEvent(id);
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    setTagsSelected(val) {},
    closePopupPropertyType(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
  },
};
</script>
