<template>
  <b-modal
    id="modal-create-property-type"
    ref="modal-create-property-type"
    centered="centered"
    :title="(propertyType.id ? 'Modifier' : 'Ajouter') + ` un type de bien`"
    @ok="create"
  >
    <div v-if="isCreatingPropertyType">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails du bien...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formCreatePropertyType">
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="Libellé"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="propertyType.label"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="propertyType.id && !archive"
          variant="outline-danger"
          @click="archivePropertyTypeLocal(propertyType.id, propertyType.label)"
          ><feather-icon icon="ArchiveIcon"
        /></b-button>
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="propertyType.id && archive"
          variant="outline-warning"
          @click="restorePropertyTypeLocal(propertyType.id, propertyType.label)"
          ><feather-icon icon="ArrowDownIcon"
        /></b-button>
        <b-button
          class="float-right"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="ok()"
          >{{ propertyType.id ? "Modifier" : "Ajouter" }}</b-button
        >
        <b-button
          class="float-right mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      propertyType: {
        label: "",
      },
      unitList: [
        "day",
        "week",
        "quarter",
        "month",
        "year",
        "hour",
        "minute",
        "second",
        "millisecond",
      ],
    };
  },
  props: {
    archive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    popupCreatePropertyTypeEvent(id) {
      if (id > 0 && this.archive) {
        this.getPropertyTypeArchivedById({
          propertyTypeId: id,
        }).then((res) => {
          this.propertyType = res;
        });
      } else if (id > 0 && !this.archive) {
        this.getPropertyTypeById({
          propertyTypeId: id,
        }).then((res) => {
          this.propertyType = res;
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.propertyType = {
        label: null,
      };
    },
    closeModal() {
      this.$emit("closePopupPropertyType", true);
      this.$bvModal.hide("modal-create-property-type");
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreatePropertyType.validate().then((success1) => {
        if (success1) {
          if (this.propertyType.id) {
            this.updatePropertyType({
              propertyType: this.propertyType,
            }).then((res) => {
              this.closeModal();
            });
          } else {
            this.createPropertyType({
              propertyType: this.propertyType,
            }).then((res) => {
              this.closeModal();
            });
          }
        }
      });
    },
    archivePropertyTypeLocal(id, label) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce type de bien en consultant la liste de vos archives.",
          {
            title:
              'Êtes-vous sûr de vouloir archiver le type de bien "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archivePropertyTypes({
              propertyTypeIds: [id],
            }).then((res) => {
              this.closeModal();
            });
          }
        });
    },
    restorePropertyTypeLocal(id, label) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez ré-utiliser ce type de bien dans tous les modules de votre application",
          {
            title:
              'Êtes-vous sûr de vouloir restaurer le type de bien "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restorePropertyTypes({
              propertyTypeIds: [id],
            }).then((res) => {
              this.closeModal();
            });
          }
        });
    },
    ...mapActions([
      "createPropertyType",
      "updatePropertyType",
      "getPropertyTypeById",
      "archivePropertyTypes",
      "restorePropertyTypes",
      "getPropertyTypeArchivedById",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingPropertyType"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
};
</script>
